import React, { createRef } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql, Link } from 'gatsby'
import { CartContext } from '../components/shared/CartProvider'
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from '../layouts'

import '../pages/index.scss'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    contentfulSellableIngredient: {
      name: string
      priceCents: integer
      quantityDescription: string
      sku: string
      slug: string
      brand: string
      picture: {
        resize: {
          src
        }
      }
      description: {
        raw: string
      }
    }
  }
}

const description = (product) => {
  var shortDescription = ""

  if (product.description?.raw) {
    const richDescription = JSON.parse(product.description.raw)
    richDescription.content = [richDescription.content[0]]
    shortDescription = documentToPlainTextString(richDescription)
  } else {
    shortDescription = ""
  }

  return shortDescription
}

const structuredData = (product) => {
  const shortDescription = description(product)

  return {
    "@context": "http://schema.org",
    "@id": `https://www.elmfoods.com/products/${product.slug}`,
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": product.brand
    },
    "description": shortDescription,
    "url": `https://www.elmfoods.com/products/${product.slug}`,
    "sku": product.sku,
    "name": product.name,
    "image": product.picture?.resize?.src,
    "offers": [
      {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": product.priceCents / 100,
        "salePrice": product.priceCents / 100,
        "availability": "http://schema.org/InStock",
        "itemCondition": "http://schema.org/NewCondition",
        "url": `https://www.elmfoods.com/products/${product.slug}`,
        "sku": product.sku,
        "seller": { "@type": "Organization", "name": "ELM Foods" },
        "name": product.name
      }
    ]
  }
}

const PageTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  const product = data.contentfulSellableIngredient
  product.recipes = product.recipe_ingredient?.map(ingredient => ingredient.recipe).flat().filter(recipe => !(recipe?.nonProduction) && (recipe?.slug))

  let quantityInput = createRef();

  const addToCart = (cart) => {
    cart.add(product.sku, parseInt(quantityInput.current.value))
  }

  const title = `${product.name} | ELM Foods`
  const shortDescription = description(product)
  const shareTitle = product.name
  const metaImage = {
    src: product.picture?.resize?.src,
    height: product.picture?.resize?.height,
    width: product.picture?.resize?.width
  }

  return (
    <Layout childTitle={title} childDescription={shortDescription} shareTitle={shareTitle} structuredData={structuredData(product)} metaImage={metaImage}>
      <div className="container-fluid bg-brand position-relative">
        <div className="container">
          <div className="row pt-4">
            <div className="col-12 col-md-6 col-print-4 offset-print-1 text-center">
              <img className="img-fluid mh-100" src={product.picture?.resize?.src} alt="The recipe" />
            </div>
            <div className="col-12 col-md-6 col-print-6 offset-print-1 pt-print-0 product-info d-flex flex-column justify-content-center">
              <h1 className="display-2">{product.name}</h1>
              <h5>{product.brand}</h5>
              <p className="small">
                <span>${(product.priceCents / 100).toFixed(2)} </span>
                {product.quantityDescription &&
                  <span>
                    per {product.quantityDescription}
                  </span>
                }
              </p>
              {product.description &&
                <div className="small">
                  {renderRichText(product.description)}
                </div>
              }
              <div className="addToCart row">
                <div className="col-4">
                  <select id="quantity" className="form-control" type="number" ref={quantityInput}>
                    {[1, 2, 3, 4, 5, 6].map((option) => {
                      return (
                        <option value={option}>{option}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-8">
                  <CartContext.Consumer>
                    {(cart) => {
                      return (
                        <button className="btn btn-block btn-primary btn-lg" onClick={() => {
                          addToCart(cart)
                        }}>Add To Basket</button>
                      )
                    }}
                  </CartContext.Consumer>
                </div>
              </div>
            </div>
          </div>
          {product.ingredients &&
            <div className="row">
              <div className="col-12">
                <h4>Ingredients</h4>
                <p className="smaller">{renderRichText(product.ingredients)}</p>
              </div>
            </div>
          }
          {product.recipes &&
            <>
              <div className="row pt-4">
                <div className="col-12">
                  <h4>Recipes Using {product.name}</h4>
                </div>
              </div>
              <div className="row scroll-horizontal">
                {product.recipes.map((recipe) => {
                  return (
                    <div className="col-3" key={`recipe-key-${recipe.slug}`}>
                      <Link to={`/recipes/${recipe.slug}`} >
                        <img className="img-fluid bg-light" src={recipe.rectangularImage?.resize?.src} alt={recipe.rectangularImage?.description} />
                        <h5 className="small font-weight-light text-center">{recipe.name}</h5>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </>
          }
          <div className="col-12 text-center mt-4">
            <Link to="/plantry">
              Shop all our ingredients in the ELM Plantry
            </Link>
          </div>
        </div>
      </div>
    </Layout >
  )
}

export default PageTemplate

export const query = graphql`
  query Product($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    contentfulSellableIngredient(slug: { eq: $slug }) {
      name
      priceCents
      quantityDescription
      sku
      slug
      brand
      ingredients {
        raw
      }
      picture {
        resize(height: 800) {
          src
          height
          width
        }
      }
      description {
        raw
      }
      recipe_ingredient {
        recipe {
          name
          slug
          rectangularImage {
            resize(height: 300) {
              src
            }
            description
          }
          nonProduction
        }
      }
    }
  }
`
// $slug: String!
